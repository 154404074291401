import * as React from "react";

import { Theme, ThemeProvider } from "@emotion/react";
import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from "@mui/material/styles";

interface Props {
  theme: Partial<Theme>;
  children: React.ReactNode;
}

const DJPThemeProvider = ({ theme, children }: Props) => {
  return (
    <MuiThemeProvider theme={createTheme()}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  );
};

export default DJPThemeProvider;
