import * as React from "react";
import styles from "../styles/layouts/mobile.module.css";
import flex from "../styles/flex.module.css";
import { ThemeColorType } from "../../common/theme";
import { CampaignInfo } from "../../common/types";
import constants from "../../common/constants";

import CampaignLogo from "../components/CampaignLogo";
import DipjarLogo from "../components/DipjarLogo";
import CardLogos from "../components/CardLogos";
import Thermometer from "../components/Thermometer";
import ThemeBackground from "../components/ThemeBackground";
import DonorAlerts, { filterRecents } from "../components/DonorAlerts";
import Text from "../components/Text";
import LabeledIcon from "../components/LabeledIcon";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import CampaignQRCode from "../components/CampaignQRCode";

export type Props = {
  theme: ThemeColorType;
  thanks: boolean;
  campaign: CampaignInfo;
  stale: boolean;
};

export default function Mobile(props: Props) {
  const { campaign, theme, stale } = props;
  const config = campaign.config;
  const alerts = filterRecents(
    campaign.recents,
    config.donorDisplayTimeMs,
    1,
  ).map((recent) => {
    return {
      message: `Thank you, ${recent.name}`,
      date: recent.date,
    };
  });

  const goalUnit = campaign.config.goalUnit;
  const goalUnitPrice = campaign.config.goalUnitPrice;
  const goalUnitEnabled = campaign.config.goalUnitEnabled;

  let units: string | undefined;
  let unitsGoal: number;
  let unitsRaised: number;
  let unitsFormatter: (n: number) => string;

  if (goalUnitEnabled && goalUnit && goalUnitPrice) {
    unitsGoal = Math.floor((campaign.goal || 0) / goalUnitPrice);
    unitsRaised = Math.floor(campaign.raised / goalUnitPrice);
    unitsFormatter = (n) => n.toLocaleString();
    units = goalUnit;
  } else {
    unitsGoal = campaign.goal || 0;
    unitsRaised = campaign.raised;
    unitsFormatter = constants.moneyFormat.format;
  }

  // if goal is 0, display "Raised X so far!" instead of "Raised X of X"
  const raisedText =
    unitsGoal !== 0
      ? `Raised ${unitsFormatter(unitsRaised)} of ${unitsFormatter(
          unitsGoal,
        )} ${units || ""}`
      : `Raised ${unitsFormatter(unitsRaised)} so far!`;

  return (
    <div className={styles.body}>
      <section className={styles.topHalf}>
        <ThemeBackground theme={theme} config={config}>
          <div className={flex.growColumn}>
            {stale && (
              <div className={styles.connFailWrapper}>
                <LabeledIcon
                  icon={WifiOffIcon}
                  label={constants.connectionFailureLabel}
                  color={theme.colors.secondary}
                />
              </div>
            )}
            <div className={styles.topColumn}>
              {config.logo && (
                <CampaignLogo
                  href={constants.baseSparkURL + campaign.url}
                  src={config.logo}
                />
              )}
              {props.thanks && alerts.length > 0 && (
                <DonorAlerts alerts={alerts} colors={theme.colors} />
              )}
              <Text mobilePrimary color={theme.colors.primary}>
                {config.title || campaign.name}
              </Text>
              <div className={styles.thermometerWrapper}>
                <Thermometer
                  type={config.thermoType}
                  height={40}
                  colors={theme.colors}
                  max={unitsGoal}
                  amount={unitsRaised}
                  units={units}
                />
              </div>
            </div>
          </div>
        </ThemeBackground>
      </section>
      <section className={styles.bottomHalf}>
        <Text secondary>{raisedText}</Text>
        <Text>
          <div
            className={styles.campaignDescription}
            dangerouslySetInnerHTML={{ __html: config.description }}
          />
        </Text>
        {config.qr && (
          <div className={styles.qrColumn}>
            <CampaignQRCode
              campaign={campaign}
              theme={theme}
              styles={styles}
              isDesktop={false}
              qrCodeSize={160}
            />
          </div>
        )}
        <div className={styles.djLogoRow}>
          <DipjarLogo theme="light" />
          {config.qr && <CardLogos className={flex.floatRight} />}
        </div>
      </section>
    </div>
  );
}
