import "@emotion/react";
import styled from "@emotion/styled/macro";

export const SmallSpacer = styled.div`
  height: 0.5rem;
`;

export const Spacer = styled.div`
  height: 1rem;
`;

export const LargeSpacer = styled.div`
  height: 2rem;
`;

export const CenteredVerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const MainContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  min-height: 100vh;
  width: 100vw;
  padding: 2rem 0rem;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  min-height: 3rem;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0rem 0.25rem 0.25rem 0rem ${(props) => props.theme.colors.shadow};
  color: ${(props) => props.theme.colors.text};
  text-align: center;
`;

export const ButtonsAndBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0rem 0.25rem 0.25rem 0rem ${(props) => props.theme.colors.shadow};
  color: ${(props) => props.theme.colors.text};
  padding: 0.5rem 0;
  opacity: 90%;
  width: 100%;
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  gap: 0.25rem;
  padding: 0.25rem 1rem;
`;

export const ProgressBarTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 1.5rem);
  padding: 0.2rem 0;
  color: ${(props) => props.theme.colors.text};
  opacity: 1;
`;

export const PaddedButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  gap: 0.5rem;
  padding: 0 2rem;
  box-sizing: border-box;
  width: 100%;
`;

export const DonationButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  gap: 0.5rem;
  padding: 0 2rem;
  box-sizing: border-box;
`;

export const DonationButtonsRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  gap: 0.5rem;
  padding: 0 0.5rem;
  box-sizing: border-box;
  width: 100%;
`;

export const IconIslandWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const IconIsland = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  width: 16rem;
  height: 16rem;
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0rem 0.25rem 0.25rem 0rem ${(props) => props.theme.colors.shadow};
  border-radius: 1rem;
  padding: 2rem;
`;

export const LargeIconContainer = styled.div`
  width: 7rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: ${(props) => props.theme.colors.text};
  font-size: 10rem;
`;

export const ButtonIconContainer = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NavButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 5rem;
  gap: 1.5rem;
`;

export const LoginButtonContainer = styled.div`
  width: 20rem;
  height: 4rem;
`;

export const CustomerLogoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem;
`;
