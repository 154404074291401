import { CampaignInfo, CampaignConfig } from "../types";
import constants from "../constants";
import { getFullAPI } from "../utils";

interface HttpResponse {
  status: number;
  data: unknown;
  headers?: Record<string, string>;
}

const fetchCampaign = (campaignName: string): Promise<CampaignInfo> => {
  const request = fetch(
    getFullAPI("/public-data/v1/campaigns/get/" + campaignName),
  ).then((res) => {
    if (!res.ok) {
      throw res;
    } else {
      return res.json();
    }
  });

  return request.then((j: any) => {
    const conf = j.config;
    let qrUrl = conf.qr || null;
    if (
      qrUrl &&
      !qrUrl.startsWith("http://") &&
      !qrUrl.startsWith("https://")
    ) {
      qrUrl = `https://${conf.qr}`;
    }
    const config: CampaignConfig = {
      logo: conf.logo ? conf.logo : null,
      theme: conf.theme || "dark",
      offset: conf.offset || 0,
      goalObject: conf.goalObject || null,
      thermoType: conf.thermoType || "orig",
      description: conf.description || "",
      primaryColor: conf.primaryColor,
      secondaryColor: conf.secondaryColor,
      filterColor: conf.filtercolor || null,
      qr: qrUrl,
      donorPaysFees: conf?.donorPaysFees || null,
      backgroundImageURL: conf.backgroundImage
        ? constants.baseResources + conf.backgroundImage
        : null,
      goalObjectPrice: conf.goalObjectPrice || 0,
      donorDisplayTimeMs:
        parseInt(conf.donorDisplayTime) * 60 * 1000 ||
        constants.defaultDonorDisplayTimeMs,
      noFullscreen: conf.brightSign ?? false,
      noTint: conf.noTint || false,
      amounts: conf.amounts || constants.defaultDonationAmounts,
      goalUnit: conf.goalUnit || "",
      goalUnitPrice: conf.goalUnitPrice || 0,
      goalUnitEnabled: conf.goalUnitEnabled || false,
      title: conf.title || "",
    };

    const info: CampaignInfo = {
      startDate: new Date(j.startDate),
      endDate: new Date(j.endDate),
      name: j.name,
      uuid: j.uuid,
      url: j.url,
      goal: parseInt(j.goal) || 0,
      raised: parseInt(j.raised),
      hasSV: j.hasSV ?? false,
      recents: j.recents ?? [],
      hasOnline: j.hasOnline ?? false,
      orgId: j.orgId ?? null,
      shortId: j.shortId ?? null,
      orgName: j.orgName ?? "",
      config,
    };

    if (info.hasSV && (!info.config.qr || info.config.qr === "")) {
      if (info.hasOnline) {
        info.config.qr = `${process.env.REACT_APP_DJO_DOMAIN}/donate/${j.url}`;
      } else {
        info.config.qr = `${
          process.env.REACT_APP_DASHBOARD_DOMAIN ||
          process.env.PROD_DASHBOARD ||
          "https://dashboard.dipjar.com"
        }/sparkvirtual/${j.url}?from=qr`;
      }
    }

    return info;
  });
};

export { fetchCampaign };
