import * as React from "react";
import styles from "../styles/components/text.module.css";
import { DARK_TEXT } from "../../common/theme";

type Props = {
  primary?: boolean;
  secondary?: boolean;
  mobilePrimary?: boolean;
  color?: string;
  className?: string;
  children: React.ReactNode;
};

export default function Text(props: Props) {
  const style: React.CSSProperties = {
    color: props.color || DARK_TEXT,
  };

  if (props.primary) {
    return (
      <h2 className={styles.primary} style={style}>
        {props.children}
      </h2>
    );
  }
  if (props.mobilePrimary) {
    return (
      <h2 className={styles.mobilePrimary} style={style}>
        {props.children}
      </h2>
    );
  }
  if (props.secondary) {
    return (
      <h4 className={styles.secondary} style={style}>
        {props.children}
      </h4>
    );
  }
  return (
    <span className={props.className} style={style}>
      {props.children}
    </span>
  );
}
