import React from "react";
import "@emotion/react";
import DJPThemeProvider from "../../common/theme/DJPThemeProvider";
import { DONOR_DATA_THEME } from "../../common/theme/themes";

export default function DonorDataPage({
  children,
}: React.PropsWithChildren<unknown>) {
  return (
    <DJPThemeProvider theme={DONOR_DATA_THEME}>{children}</DJPThemeProvider>
  );
}
