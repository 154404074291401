import "@emotion/react";
import styled from "@emotion/styled/macro";

export const HeaderText = styled.span`
  font-family: Poppins;
  font-size: ${(props) => props.theme.fontSizes.xl};
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights.bold}
  line-height: normal;
`;

export const Text = styled.span`
  font-family: Poppins;
  color: ${(props) => props.theme.colors.text};
  margin: 0;
  position: relative;
  font-weight: ${(props) => props.theme.fontWeights.regular}
  font-size: ${(props) => props.theme.fontSizes.md};
  line-height: 1.25rem;
  text-align: center;
`;

export const SmallText = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.sm};
`;

export const LargeText = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.lg};
`;

export const InvertedColorText = styled(Text)`
  color: ${(props) => props.theme.colors.white};
`;

export const AccentModifier = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;

export const LargeAccentModifier = styled(LargeText)`
  font-weight: ${(props) => props.theme.fontWeights.bold};
`;
