import * as React from "react";
import SomethingWrong from "../components/SomethingWrong";

type Props = {};

export default class ErrorBoundary extends React.Component<
  {},
  { error: Error | null }
> {
  constructor(props: Props) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("React error caught:", error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return <SomethingWrong />;
    }
    return this.props.children;
  }
}
