import * as React from "react";
import { CampaignInfo } from "../../common/types";
import { ThemeColorType } from "../../common/theme";
import QRCode from "qrcode.react";
import Text from "./Text";

export type Props = {
  campaign: CampaignInfo;
  theme: ThemeColorType;
  styles: any;
  isDesktop: boolean;
  qrCodeSize: number;
};

export default function CampaignQRCode(props: Props) {
  const { campaign, theme, styles, isDesktop, qrCodeSize } = props;
  const config = campaign.config;
  let donateMessage = campaign.hasOnline ? "DipJar Online" : "SparkVirtual";
  const qr = config.qr as string;

  return isDesktop ? (
    <>
      <Text color={theme.colors.secondary} className={styles.qrTopText}>
        Donate now <br /> with {donateMessage}
      </Text>
      <div className={styles.qrPadding}>
        <QRCode size={qrCodeSize} bgColor="white" fgColor="black" value={qr} />
      </div>
      <div className={styles.qrBottomSpace} />
    </>
  ) : (
    <>
      <QRCode size={qrCodeSize} bgColor="white" fgColor="black" value={qr} />
      <Text className={styles.qrLabel}>Donate now with {donateMessage}</Text>
    </>
  );
}
