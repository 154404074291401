import "@emotion/react";

declare module "@emotion/react" {
  /**
   * Provides the default Spark theme structure.
   *
   * The following colors are provided:
   * - `primary`    — The go-to option for adding color to the app.
   * - `accent`     — Used sparingly to draw attention to certain elements.
   * - `background` — The app's background color.
   * - `component`  — Used to define component boundaries, should contrast against `background`.
   * - `complement` — A contrasting color to `primary`.
   * - `gradient`   — A subtle gradient based on `primary`.
   * - `text`       — Used for text elements.
   * - `subtle`     — Used against `background` for decorative elements.
   *
   * Assets:
   * - `background` — Used to store the background image for a campaign.
   * - `backgroundGradient` — Stores the background gradient for Jar Pro to apply on top of the `background`.
   */
  export interface Theme {
    colors: {
      primary: string;
      accent?: string;
      background?: string;
      component?: string;
      complement?: string;
      gradient?: string;
      greyLight?: string;
      greyDark?: string;
      text?: string;
      subtle?: string;
      error?: string;
      control?: string;
      shadow?: string;
      opacityPrimary?: string;
      defaultBlue?: string;
      white?: string;
      highlight?: string;
    };
    fontSizes: {
      xs: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
    };
    fontWeights: {
      light?: string;
      regular?: string;
      medium?: string;
      semibold?: string;
      bold?: string;
    };
    assets: {
      background?: string;
      backgroundGradient?: string;
      gradient?: string;
    };
    breakpoints: {
      sm?: number;
      md?: number;
      lg?: number;
      xl?: number;
      xxl?: number;
    };
  }
}

export interface TextColors {
  primary: string;
  secondary: string;
}
export interface ThemeColors extends TextColors {
  background?: string;
  bar?: string;
}

export type ThemeType = "light" | "dark";
export type CampaignThemeType = "custom" | ThemeType;

export type ThemeColorType = {
  colors: ThemeColors;
};

export const DARK_TEXT = "rgba(0, 0, 0, 0.82)";

const themes: Record<ThemeType, ThemeColorType> = {
  light: {
    colors: {
      primary: "#2E4B58",
      secondary: "#4193A6",
      background: "white",
      bar: "#E6E6E6",
    },
  },
  dark: {
    colors: {
      primary: "white",
      secondary: "#4193A6",
      background: "#12272e",
      bar: "#E6E6E6",
    },
  },
};

export default themes;
