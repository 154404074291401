import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

if (process.env.NODE_ENV !== "production") {
  document.title = `${document.title} (${process.env.NODE_ENV})`;
}

if (process.env.NODE_ENV !== "test") {
  ReactDOM.render(<App />, document.getElementById("root"));
}

serviceWorker.unregister();
