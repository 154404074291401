import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import React from "react";
import { StyledEngineProvider } from "@mui/material";
import { DeviceMode } from "./common/types";
import ErrorBoundary from "./common/application/ErrorBoundary";
import DJPThemeProvider from "./common/theme/DJPThemeProvider";
import { LIGHT_THEME } from "./common/theme/themes";
import { CampaignProvider } from "./common/application/CampaignProvider";
import DonorDataPage from "./donordatapage/application/DonorDataPage";
import DonorDataScreen from "./donordatapage/layouts/DonorDataScreen";
import jsPDF from "jspdf";
import SparkLayout from "./sparkdisplay/application/SparkLayout";
import { OrganizationProvider } from "./common/application/OrganizationContext";

const SparkRouter = () => {
  return (
    <>
      {/* MODIFICATIONS */}
      <Route path="/mobile/:campaign">
        <SparkLayout mode={DeviceMode.Mobile} />
      </Route>
      <Route path="/v2/:campaign">
        <SparkLayout mode={DeviceMode.Mobile} />
      </Route>
      <Route path="/v3/:campaign">
        <SparkLayout mode={DeviceMode.Mobile} />
      </Route>
      <Route exact path="/:campaign">
        <SparkLayout mode={DeviceMode.Desktop} />
      </Route>
    </>
  );
};

const PostSaleRouter = () => {
  return (
    <Route exact path="/donordata/:campaignId/:transactionId?">
      <DonorDataPage>
        <DonorDataScreen generatePdf={new jsPDF()} />
      </DonorDataPage>
    </Route>
  );
};

const AppRouter = () => {
  return (
    <ErrorBoundary>
      <SparkRouter />
      <PostSaleRouter />
      <Route path="/">
        <></>
      </Route>
    </ErrorBoundary>
  );
};

const App: React.FC = () => {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <Switch>
          <OrganizationProvider>
            <CampaignProvider>
              <StyledEngineProvider injectFirst>
                <DJPThemeProvider theme={LIGHT_THEME}>
                  <AppRouter />
                </DJPThemeProvider>
              </StyledEngineProvider>
            </CampaignProvider>
          </OrganizationProvider>
        </Switch>
      </BrowserRouter>
    </React.StrictMode>
  );
};

export default App;
