import "@emotion/react";
import styled from "@emotion/styled/macro";
import { Button, FormGroup, TextField } from "@mui/material";

/**
 * {@link InputField}
 */
export const StyledTextField = styled(TextField)`
  background: ${(props) => props.theme.colors.complement};
  border-radius: 0.25rem;
  font-size: 0.75rem;
  width: 100%;

  && {
    background-color: ${(props) => props.theme.colors.complement};
    color: ${(props) => props.theme.colors.primary};

    &:hover {
      background-color: ${(props) => props.theme.colors.complement};
    }

    border: none;
    border-radius: 0.625rem;

    .MuiFilledInput-underline:before,
    .MuiFilledInput-underline:after {
      content: none;
    }

    .MuiFilledInput-root {
      border-radius: 0.625rem;
    }

    .MuiInputBase-root {
      input:-webkit-autofill {
        border-radius: 0.625rem;
      }
    }
  }
`;

export const SubmitButton = styled(Button)`
    color: ${(props) => props.theme.colors.control}
    font-family: Poppins, sans-serif;
    font-size: ${(props) => props.theme.fontSizes.sm};
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights.medium}
    line-height: normal;
    background-color: ${(props) => props.theme.colors.opacityPrimary};
    width: 100%;
    height: 2.25rem;
    max-width: 22rem;
    gap: 0.5rem;

    &:hover {
        background-color: ${(props) => props.theme.colors.opacityPrimary};
    }

    &:active {
        background-color: ${(props) => props.theme.colors.opacityPrimary};
    }
`;

export const VerticalColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledRadioGroup = styled(FormGroup)`
  flex-direction: column;
  padding: 1rem;
  border-radius: 0.5rem;
`;
