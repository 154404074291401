import "@emotion/react";
import styled from "@emotion/styled/macro";
import { MdMarkEmailRead, MdOutlineDownloadForOffline } from "react-icons/md";

export const ResponsiveBackground = styled.div`
  height: 100%;
  width: 100%;
  background: ${(props) => props.theme.assets.background};
  background-size: cover;
  background-position: center center;
  position: fixed;
  top: 0;
`;

export const DjBrandingImg = styled.img`
  width: 7.5rem;
  height: 100%;
`;

export const StyledEmailIcon = styled(MdMarkEmailRead)`
  width: 3.5rem;
  height: 100%;
`;

export const StyledDownloadIcon = styled(MdOutlineDownloadForOffline)`
  width: 3.5rem;
  height: 100%;
`;
