import "@emotion/react";
import styled from "@emotion/styled/macro";

export const SmallSpacer = styled.div`
  height: 0.5rem;
`;

export const Spacer = styled.div`
  height: 1rem;
`;

export const LargeSpacer = styled.div`
  height: 2rem;
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  background: ${(props) => props.theme.colors.background};
`;

export const FormOuter = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  gap: 1.5rem;
  flex-shrink: 0;
`;

export const WrapperInnerTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: top;
`;

export const WrapperInnerBottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  margin: 1rem 1rem 0rem;
  padding: 1rem 0.5rem;
  max-width: 22rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 2.5rem;
  background: ${(props) => props.theme.colors.component};
  box-shadow: 0rem 0.25rem 0.25rem 0rem ${(props) => props.theme.colors.shadow};
`;

export const FormWrapper = styled.div`
  display: flex;
  margin: 1rem;
  padding: 1rem 0.5rem;
  max-width: 22rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 2.5rem;
  background: ${(props) => props.theme.colors.component};
  box-shadow: 0rem 0.25rem 0.25rem 0rem ${(props) => props.theme.colors.shadow};
`;

export const FormHeader = styled.div`
  text-align: center;
  font-size: 1.15rem;
  font-style: normal;
  font-weight: ${(props) => props.theme.fontWeights?.regular};
  line-height: normal;
  margin: 0rem 1rem;
`;

export const FormRow = styled.div`
  display: flex;
  width: calc(100% - 1rem);
  justify-content: space-between;
  margin: 0.5rem;

  &.horizontal {
    gap: 0.75rem;
  }

  &.vertical {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.25rem;
  }
`;

export const FormInputDisclaimer = styled.div`
    color: ${(props) => props.theme.colors.primary};
    text-align: center;
    font-family: Poppins;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: ${(props) => props.theme.fontWeights?.regular}
    line-height: normal;
`;

export const SubmitAndBranding = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: 1rem;
  gap: 0.2rem;
`;

export const DJBrandingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0.2rem;
`;

export const PostSubmitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const SubmissionMessageWrapper = styled.div`
  display: flex;
  max-width: 22rem;
  margin: 1rem;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75rem;
  align-self: stretch;
  border-radius: 2.5rem;
  background: ${(props) => props.theme.colors.component};
  box-shadow: 0rem 0.2rem 0.2rem 0rem ${(props) => props.theme.colors.shadow};
`;

export const SubmissionMessageContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
  height: 100%;
  text-align: center;
`;
