import { ReceiptData } from "../types";

const generateReceipt = (doc: any, receiptData?: ReceiptData) => {
  const pageWidth = doc.internal.pageSize.getWidth();
  const leftMargin = 20;
  let currentY = 10; // Starting closer to the top

  if (receiptData) {
    //Header
    doc.setFontSize(20);
    const headerWidth = doc.getTextWidth("Donation Receipt");
    doc.text("Donation Receipt", (pageWidth - headerWidth) / 2, currentY);
    currentY += 5;

    // Top Divider
    doc.setLineWidth(1);
    doc.line(leftMargin, currentY, pageWidth - leftMargin, currentY);
    currentY += 10;

    // Organization details
    doc.setFontSize(16);
    doc.setTextColor(160, 160, 160);
    doc.text("Organization", leftMargin, currentY);
    currentY += 10;

    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    if (receiptData.orgName) {
      doc.text(receiptData.orgName ?? "", leftMargin, currentY);
      currentY += 10;
    }
    if (receiptData.orgAddress1) {
      doc.text(receiptData.orgAddress1, leftMargin, currentY);
      currentY += 10;
    }
    if (receiptData.orgAddress2) {
      doc.text(receiptData.orgAddress2, leftMargin, currentY);
      currentY += 10;
    }
    doc.text(
      `${receiptData.orgCity ?? ""}, ${receiptData.orgState ?? ""}, ${
        receiptData.orgZipCode ?? ""
      }`,
      leftMargin,
      currentY,
    );
    if (receiptData.orgPhoneNumber) {
      currentY += 10;
      doc.text(receiptData.orgPhoneNumber, leftMargin, currentY);
    }
    currentY += 5;

    // Divider between address and donation amount header
    doc.setLineWidth(1);
    doc.line(leftMargin, currentY, pageWidth - leftMargin, currentY);
    currentY += 10;

    // Donation details
    doc.setFontSize(16);
    doc.setTextColor(160, 160, 160);
    doc.text("Donor", leftMargin, currentY);
    currentY += 10;

    doc.text(`${receiptData.date}`, leftMargin, currentY);
    currentY += 10;
    doc.text("Transaction Type: PURCHASE", leftMargin, currentY);
    currentY += 10;
    doc.text(`Logo: ${receiptData.logo}`, leftMargin, currentY);
    currentY += 10;
    doc.text(`Entry: ${receiptData.paymentMethod}`, leftMargin, currentY);
    currentY += 10;

    doc.text(
      `CC Last Four: ${receiptData.creditCardLastFour}`,
      leftMargin,
      currentY,
    );
    currentY += 10;

    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);

    if (receiptData.donorFirstName && receiptData.donorLastName) {
      doc.text(
        `${receiptData.donorFirstName} ${receiptData.donorLastName}`,
        leftMargin,
        currentY,
      );
      currentY += 15;
    }

    doc.setFontSize(16);
    doc.setTextColor(160, 160, 160);
    doc.text("Donation Amount", leftMargin, currentY);
    currentY += 10;

    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    if (receiptData.donationAmount) {
      doc.text(`$${receiptData.donationAmount}`, leftMargin, currentY);
      currentY += 15;
    }

    // Divider
    doc.setLineWidth(1);
    doc.line(leftMargin, currentY, pageWidth - leftMargin, currentY);
    currentY += 10;

    doc.setFontSize(18);
    doc.setTextColor(0, 0, 0);
    doc.text(`Merchant ID: ${receiptData.merchantId}`, leftMargin, currentY);
    currentY += 10;
    doc.text(`Terminal ID: ${receiptData.terminalId}`, leftMargin, currentY);
    currentY += 10;
    if (receiptData.referenceNumber) {
      doc.text(
        `Reference Number: ${receiptData.referenceNumber}`,
        leftMargin,
        currentY,
      );
      currentY += 10;
    }

    if (receiptData.sequenceNumber) {
      doc.text(
        `Sequence Number: ${receiptData.sequenceNumber}`,
        leftMargin,
        currentY,
      );
      currentY += 10;
    }

    doc.text("SIGNATURE NOT REQUIRED", leftMargin, currentY);
    currentY += 10;

    // Bottom Divider
    doc.setLineWidth(1);
    doc.line(leftMargin, currentY, pageWidth - leftMargin, currentY);
    currentY += 5;

    // Footer
    doc.setFontSize(16);
    doc.setTextColor(160, 160, 160);
    doc.text("Thank you for your donation!", leftMargin, currentY);
    currentY += 5;
    const copyWidth = doc.getTextWidth("CUSTOMER COPY");
    doc.text("CUSTOMER COPY", (pageWidth - copyWidth) / 2, currentY);
    currentY += 5;

    // Download the PDF
    doc.save("DonationReceipt.pdf");
  }
};

export { generateReceipt };
