const constants = {
  apiEndpointDJO:
    process.env.REACT_APP_DJO_GATEWAY_ENDPOINT ||
    "https://djo-gateway-staging.herokuapp.com",
  apiEndpoint:
    process.env.REACT_APP_API_ENDPOINT || "https://dashboard.dipjar.com",
  baseResources: "https://s3.amazonaws.com/dipjar.com/campaignAssets/",
  baseSparkURL: "https://spark.dipjar.com/",
  configPollIntervalMs: 180000,
  configPollMaxRetries: 5,
  defaultDonorDisplayTimeMs: 3 * 60 * 1000,
  moneyFormat: new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    currencyDisplay: "symbol",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }),
  connectionFailureLabel: "Reconnecting ...",
  defaultDonationAmounts: [10.0, 25.0, 50.0, 100.0],
};

export default constants;
