import * as React from "react";
import styles from "../styles/components/campaignLogo.module.css";
import { BaseProps } from "../../common/types";
import constants from "../../common/constants";

interface Props extends BaseProps {
  src: string;
  href: string;
}

export default function CampaignLogo(props: Props) {
  return (
    <img
      className={styles.campaignLogo}
      alt="Campaign Logo"
      src={constants.baseResources + props.src}
    />
  );
}
