import * as React from "react";
import "@emotion/react";
import styled from "@emotion/styled/macro";
import { RecentDonor } from "../../common/types";
import { ThemeColors } from "../../common/theme";

import { CSSTransition, TransitionGroup } from "react-transition-group";

export type DonationAlert = {
  message: string;
  date: Date;
};

export type Props = {
  alerts: DonationAlert[];
  colors: ThemeColors;
};

export function filterRecents(
  recents: RecentDonor[],
  displayTimeMs: number,
  max: number | null,
): RecentDonor[] {
  const now = Date.now();
  const result = recents.filter(
    (r) => r.date.valueOf() + displayTimeMs >= now.valueOf(),
  );
  return max ? result.slice(0, max) : result;
}

export default function DonorAlerts(props: Props) {
  return (
    <TransitionGroup component={DonorAlertList}>
      {props.alerts.map((alert) => (
        <CSSTransition
          key={`${alert.date.valueOf()}-${alert.message}`}
          timeout={500}
        >
          <DonorAlertItem>{alert.message}</DonorAlertItem>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
}

const DonorAlertList = styled.ul`
  display: flex;
  flex-direction: column-reverse;
  gap: 0.5rem;
  justify-content: flex-end;
  padding: 0;
  margin: 2rem 0;
  align-items: center;

  @media screen and (max-width: 991px) {
    margin: 0;
  }
`;

const DonorAlertItem = styled.li`
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  font-size: 1.4rem;
  font-weight: ${(props) => props.theme.fontWeights.regular};
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;
  animation: fadeIn ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;

  &.exit,
  &.exit-active {
    display: none;
  }

  @media screen and (max-width: 991px) {
    font-size: 1rem;
  }
`;
