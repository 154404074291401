import "@emotion/react";
import * as S from "../styles/styles";

export type Props = {
  primaryText: string;
  secondaryText: string;
};

export function Header(props: Props) {
  return (
    <S.Header>
      <S.H1Text>{props.primaryText}</S.H1Text>
      <S.H2Text>{props.secondaryText}</S.H2Text>
    </S.Header>
  );
}
