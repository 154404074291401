import { Theme } from "@emotion/react";

export const LIGHT_THEME: Partial<Theme> = {
  colors: {
    primary: "#202020",
    accent: "#FFC600",
    background: "#FFFFFF99",
    component: "#FFFFFF99",
    complement: "#FFFFFF",
    greyLight: "#F0F0F0",
    greyDark: "#DDDDDD",
    text: "#202020",
    subtle: "#DADADA",
    error: "#F93943",
    control: "#FFFFFF",
    shadow: "#00000025",
    opacityPrimary: "#20202099",
    defaultBlue: "#00B5CC",
    white: "#FFFFFF",
    highlight: "#FFC600",
  },
  assets: {
    background: "linear-gradient(158deg, #0097A9 8.2%, #A4CBE3 96.89%)",
    gradient: "linear-gradient(92.74deg, #25ACBA 0%, #44CBDA 100%)",
  },
  fontSizes: {
    xs: "1.125rem",
    sm: "1.25rem",
    md: "1.5rem",
    lg: "1.75rem",
    xl: "2rem",
  },
  fontWeights: {
    light: "300",
    regular: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
  },
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
};

export const DONOR_DATA_THEME = {
  colors: {
    primary: "#202020",
    accent: "#FFC600",
    background: "linear-gradient(158deg, #0097A9 8.2%, #A4CBE3 96.89%)",
    component: "#FFFFFF99",
    complement: "#FFFFFF",
    gradient: "linear-gradient(92.74deg, #25ACBA 0%, #44CBDA 100%)",
    text: "#202020",
    subtle: "#DADADA",
    error: "#F93943",
    control: "#FFFFFF",
    shadow: "#00000025",
    opacityPrimary: "#20202099",
  },
  assets: {
    backgroundGradient:
      "linear-gradient(0, rgba(16, 50, 62, 1) 25%, rgba(16, 50, 62, 0))",
  },
  fontSizes: {
    xs: "1.125rem",
    sm: "1.25rem",
    md: "1.5rem",
    lg: "1.75rem",
    xl: "2rem",
  },
  fontWeights: {
    light: "300",
    regular: "400",
    medium: "500",
    semibold: "600",
    bold: "700",
  },
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
};

export const ONLINE_GIVING_THEME = {
  colors: {
    primary: "#00B5CC",
    accent: "#FFC600",
    background: "#FFFFFF",
    component: "#F9F9F9",
    complement: "#F0F0F0",
    gradient: "linear-gradient(92.74deg, #25ACBA 0%, #44CBDA 100%)",
    text: "#4A4A4A",
    subtle: "#DADADA",
    error: "#F93943",
    control: "#244C5A",
    shadow: "#00000025",
    opacityPrimary: "#00B5CC99",
    defaultBlue: "#00B5CC",
  },
  assets: {
    background: "linear-gradient(92.74deg, #25ACBA 0%, #44CBDA 100%)",
  },
  breakpoints: {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  },
};
