import * as React from "react";
import styles from "../styles/components/labeledIcon.module.css";
import { DARK_TEXT } from "../../common/theme";
import Text from "./Text";
import { SvgIconComponent } from "@mui/icons-material";

export type Props = {
  label: string;
  icon: SvgIconComponent;
  color?: string;
};

export default function LabeledIcon(props: Props) {
  const { label, icon, color } = props;

  const Icon = icon;

  const primary = color || DARK_TEXT;
  const style: React.CSSProperties = {
    color: primary,
  };

  return (
    <div className={styles.wrapper} style={style}>
      <Icon className={styles.icon} />
      <Text className={styles.label} color={primary}>
        {label}
      </Text>
    </div>
  );
}
