import constants from "./constants";

/**
 * Converts a number to a US comma-delimited formatted string.
 * @param number A number to convert into a string
 * @example `formatUS(1000) => "1,000"`
 */
export const formatUS = (number: number, cents: boolean) => {
  return new Intl.NumberFormat("en-US", {
    minimumFractionDigits: cents ? 2 : 0,
    maximumFractionDigits: cents ? 2 : 0,
  }).format(number);
};

/**
 * Get the full API URL from the relative request link based on the current runtime environment.
 *
 * If running on development, this will return a relative link ("/api/...") to be routed through proxy,
 * otherwise this function will compile a full link (including the pulled domain from the environment variables) to by-pass the proxy.
 *
 * @param link the relative link (without "/api") for the relevant fetch
 * @returns the full API URL to be used for the API call
 */
export const getFullAPI = (link: string) => {
  return constants.apiEndpoint + link;
};

/**
 * Get the full API URL from the relative request link based on the current runtime environment.
 *
 * If running on development, this will return a relative link ("/api/...") to be routed through proxy,
 * otherwise this function will compile a full link (including the pulled domain from the environment variables) to by-pass the proxy.
 *
 * @param link the relative link (without "/api") for the relevant fetch
 * @returns the full API URL to be used for the API call
 */
export const getFullDJOAPI = (link: string) => {
  return (
    (process.env.NODE_ENV === "development"
      ? "/api"
      : constants.apiEndpointDJO) + link
  );
};
