import * as React from "react";
import * as reactRouter from "react-router-dom";
import Desktop from "../layouts/Desktop";
import Mobile from "../layouts/Mobile";

import themes, { ThemeColorType, ThemeType } from "../../common/theme";
import { DeviceMode, RouteParams } from "../../common/types";
import NotFound from "../../common/components/NotFound";
import { CampaignContext } from "../../common/application/CampaignProvider";
import { fetchCampaign } from "../../common/helpers/CampaignHelpers";
import constants from "../../common/constants";
import LoadingScreen from "./LoadingScreen";
import { useContext, useEffect, useState } from "react";

export type Props = {
  mode?: DeviceMode;
};

export default function SparkLayout(props: Props) {
  const params = new URLSearchParams(reactRouter.useLocation()?.search);
  const displayThanks = params.get("thanks") != null;
  const { campaign, setCampaign } = useContext(CampaignContext);
  const { campaign: campaignUrl } = reactRouter.useParams<RouteParams>();
  const [loading, setLoading] = useState<boolean>(true);

  const isResponsive = !props.mode;
  const [mode, setMode] = useState<DeviceMode>(props.mode || DeviceMode.Mobile);

  useEffect(() => {
    const fetch = async () => {
      const fetchedCampaign = await fetchCampaign(campaignUrl);
      setCampaign(fetchedCampaign);
    };

    try {
      fetch();
      setLoading(false);
    } catch {
      setLoading(true);
    }

    const intervalId = setInterval(fetch, constants.configPollIntervalMs);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [campaignUrl, setCampaign]);

  useEffect(() => {
    if (isResponsive) {
      const media = window.matchMedia("(min-width: 992px)");
      const updateMode = (match: MediaQueryListEvent | MediaQueryList) => {
        setMode(match.matches ? DeviceMode.Desktop : DeviceMode.Mobile);
      };
      updateMode(media);
      media.addEventListener("change", updateMode);
      return () => media.removeEventListener("change", updateMode);
    }
  }, [isResponsive]);

  let theme: ThemeColorType = themes.light;
  const config = campaign?.config;

  if (config && config.theme === "custom") {
    theme.colors.primary = config.primaryColor || theme.colors.primary;
    theme.colors.secondary = config.secondaryColor || theme.colors.secondary;
  } else if (config && config.theme in themes) {
    theme = themes[config.theme as ThemeType];
  } else {
    theme = themes.light;
  }

  const child = (() => {
    if (campaign) {
      switch (mode) {
        case DeviceMode.Desktop:
          return <Desktop theme={theme} campaign={campaign} stale={loading} />;
        case DeviceMode.Mobile:
          return (
            <Mobile
              theme={theme}
              campaign={campaign}
              thanks={displayThanks}
              stale={loading}
            />
          );
        default:
          return <NotFound />;
      }
    } else {
      return <LoadingScreen />;
    }
  })();

  return child;
}
