import * as React from "react";
import styles from "../styles/components/thermometer.module.css";
import { ThermometerType } from "../../common/types";
import { ThemeColors } from "../../common/theme";
import constants from "../../common/constants";
import Text from "./Text";

export interface Props extends InnerProps {
  type: ThermometerType;
  noNumbers?: boolean;
}

type InnerProps = {
  height: number;
  amount: number;
  max: number;
  colors: ThemeColors;
  units?: string;
};

export function ProgressBar(props: InnerProps) {
  const { height, amount, max, colors } = props;
  const progressPercent = max === 0 ? 100 : Math.ceil((amount / max) * 100);
  const containerStyle = {
    height,
    backgroundColor: colors.bar,
  };
  const sliderStyle = {
    // `max(${progressPercent}%, ${height}px)` prevents partial circles
    width:
      progressPercent === 0 ? 0 : `max(${progressPercent}%, ${height - 5}px)`,
    backgroundColor: colors.secondary,
  };
  return (
    <div className={styles.sliderContainer} style={containerStyle}>
      <div className={styles.slider} style={sliderStyle} />
    </div>
  );
}

const DOT_SIZE_RATIO = 40;

export function ProgressDots(props: InnerProps) {
  const { height, amount, max, colors } = props;
  const [dotCount, setDotCount] = React.useState<number>(20);
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const current = containerRef.current;

    function updateDotCount() {
      if (current) {
        setDotCount(Math.floor(current.offsetWidth / DOT_SIZE_RATIO));
      }
    }
    updateDotCount();
    current.addEventListener("resize", updateDotCount);
    window.addEventListener("resize", updateDotCount);
    return () => {
      current.removeEventListener("resize", updateDotCount);
      window.removeEventListener("resize", updateDotCount);
    };
  }, [containerRef]);

  const dots = [];
  const filledDots = Math.max(Math.floor((amount / max) * dotCount), 1);
  for (let i = 0; i < dotCount; i++) {
    const dotStyle = {
      backgroundColor: filledDots <= i ? colors.bar : colors.secondary,
    };
    dots.push(<span key={i} className={styles.dot} style={dotStyle} />);
  }
  const containerStyle = { height };

  return (
    <div
      className={styles.dotContainer}
      style={containerStyle}
      ref={containerRef}
    >
      {dots}
    </div>
  );
}

export default function Thermometer(props: Props) {
  const bar =
    props.type === "dots" ? (
      <ProgressDots {...props} />
    ) : (
      <ProgressBar {...props} />
    );
  return (
    <div className={styles.wrapper}>
      {bar}
      {!props.noNumbers && props.max !== 0 && (
        <div className={styles.textRow}>
          <Text secondary color={props.colors.secondary}>
            {props.units
              ? `${props.amount.toLocaleString()} ${props.units}`
              : `${constants.moneyFormat.format(props.amount)}`}
          </Text>
          <Text secondary color={props.colors.secondary}>
            {props.units
              ? `${props.max.toLocaleString()} ${props.units}`
              : constants.moneyFormat.format(props.max)}
          </Text>
        </div>
      )}
    </div>
  );
}
