import { CampaignThemeType } from "./theme";

export interface BaseProps {
  children?: any;
}

export interface RouteParams {
  campaign: string;
}

export type Response<T> = "loading" | T | "failed";

export enum ConnectionStatus {
  Loading = "LOADING",
  Live = "LIVE",
  Stale = "STALE",
  Failed = "FAILED",
}

export type ThermometerType = "orig" | "dots";

export type DonorPaysFeesConfig = {
  fixedFee: number;
  percentageFee: number;
};

export type CampaignConfig = {
  logo: string | null;
  theme: CampaignThemeType;
  offset: number;
  title: string | null;
  goalObject: string | null;
  thermoType: ThermometerType;
  description: string;
  primaryColor: string;
  qr: string | null;
  secondaryColor: string;
  filterColor: string | null;
  donorPaysFees: DonorPaysFeesConfig | null;
  backgroundImageURL: string | null;
  goalObjectPrice: number;
  donorDisplayTimeMs: number;
  noFullscreen: boolean;
  noTint: boolean;
  amounts: number[];
  goalUnit: string;
  goalUnitPrice: number;
  goalUnitEnabled: boolean;
};

export type RecentDonor = {
  name: string;
  date: Date;
};

export type CampaignInfo = {
  startDate: Date;
  endDate: Date | null;
  name: string;
  config: CampaignConfig;
  uuid: string;
  url: string;
  hasSV: boolean;
  raised: number;
  recents: RecentDonor[];
  goal: number;
  hasOnline: boolean;
  orgId: string | null;
  shortId: string | null;
  orgName: string;
};

export enum DeviceMode {
  Desktop = "DESKTOP",
  Mobile = "MOBILE",
  Pax = "PAX",
}

export enum TransactionStatus {
  Ready = "READY",
  Success = "SUCCESS",
  Failure = "FAILURE",
  ValidationFailure = "VALIDATION_FAILURE",
}

export type Nullable<T> = T | null;

export type CampaignPaymentInfo = {
  merchantId: string;
};

export enum FetchStatus {
  Loading = "LOADING",
  Fetched = "FETCHED",
  Failed = "FAILED",
}

export interface ReceiptData {
  orgName: string;
  orgAddress1: string;
  orgAddress2?: string;
  orgCity: string;
  orgState: string;
  orgZipCode: string;
  orgPhoneNumber: string;
  donorFirstName: string;
  donorLastName: string;
  donationAmount: number;
  creditCardLastFour: string;
  date: Date;
  paymentMethod: string;
  logo: string;
  referenceNumber: string;
  sequenceNumber: string;
  merchantId: string;
  terminalId: string;
}
