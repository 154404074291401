import * as React from "react";
import styles from "../styles/layouts/desktop.module.css";
import { ThemeColorType } from "../../common/theme";
import { CampaignInfo } from "../../common/types";
import constants from "../../common/constants";

import { FullScreen, useFullScreenHandle } from "react-full-screen";
import CampaignLogo from "../components/CampaignLogo";
import CardLogos from "../components/CardLogos";
import DipjarLogo from "../components/DipjarLogo";
import DonorAlerts, { filterRecents } from "../components/DonorAlerts";
import Thermometer from "../components/Thermometer";
import FullscreenButton from "../components/FullscreenButton";
import ThemeBackground from "../components/ThemeBackground";
import Text from "../components/Text";
import LabeledIcon from "../components/LabeledIcon";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import CampaignQRCode from "../components/CampaignQRCode";

export type Props = {
  theme: ThemeColorType;
  campaign: CampaignInfo;
  stale: Boolean;
};

export default function Desktop(props: Props) {
  const { campaign, theme, stale } = props;
  const config = campaign.config;
  const alerts = filterRecents(
    campaign.recents,
    config.donorDisplayTimeMs,
    3,
  ).map((recent) => {
    return {
      message: `Thank you, ${recent.name}`,
      date: recent.date,
    };
  });

  const fullscreenHandle = useFullScreenHandle();
  const fullscreen = fullscreenHandle.active;

  const goalUnit = campaign.config.goalUnit;
  const goalUnitPrice = campaign.config.goalUnitPrice;
  const goalUnitEnabled = campaign.config.goalUnitEnabled;

  let units: string | undefined;
  let unitsGoal: number;
  let unitsRaised: number;
  let unitsFormatter: (n: number) => string;

  if (goalUnitEnabled && goalUnit && goalUnitPrice) {
    unitsGoal = Math.floor((campaign.goal || 0) / goalUnitPrice);
    unitsRaised = Math.floor(campaign.raised / goalUnitPrice);
    unitsFormatter = (n) => n.toLocaleString();
    units = goalUnit;
  } else {
    unitsGoal = campaign.goal || 0;
    unitsRaised = campaign.raised;
    unitsFormatter = constants.moneyFormat.format;
  }

  // if goal is 0, display "Raised X so far!" instead of "Raised X of X"
  const raisedText =
    unitsGoal !== 0
      ? `Raised ${unitsFormatter(unitsRaised)} of ${unitsFormatter(
          unitsGoal,
        )} ${units || ""}`
      : `Raised ${unitsFormatter(unitsRaised)} so far!`;

  return (
    <FullScreen handle={fullscreenHandle}>
      <ThemeBackground theme={theme} config={config}>
        <div className={styles.bodyColumn}>
          <div className={styles.fullscreenIconRow}>
            {!config.noFullscreen && !fullscreen && (
              <FullscreenButton
                color={theme.colors.secondary}
                onClick={fullscreenHandle.enter}
              />
            )}
            {stale && (
              <LabeledIcon
                icon={WifiOffIcon}
                label={constants.connectionFailureLabel}
                color={theme.colors.secondary}
              />
            )}
          </div>

          <div className={styles.mainColumn}>
            <div className={styles.textColumn}>
              <Text primary color={theme.colors.primary}>
                {config.title || campaign.name}
              </Text>
              {config.description && (
                <Text secondary color={theme.colors.secondary}>
                  <div
                    className={styles.campaignDescription}
                    dangerouslySetInnerHTML={{ __html: config.description }}
                  />
                </Text>
              )}
            </div>
            {config.logo && (
              <CampaignLogo
                href={constants.baseSparkURL + campaign.url}
                src={config.logo}
              />
            )}
            <div className={styles.bottomRow}>
              <DipjarLogo theme={config.theme} />
              <div className={styles.bottomColumn}>
                <div className={styles.middlePadding} />
                {alerts.length > 0 && (
                  <DonorAlerts alerts={alerts} colors={theme.colors} />
                )}
                <div className={styles.textColumn}>
                  <Text primary color={theme.colors.primary}>
                    {raisedText}
                  </Text>
                  {unitsGoal !== 0 && (
                    <Text secondary color={theme.colors.secondary}>
                      {`${unitsFormatter(unitsGoal)} Goal`}
                    </Text>
                  )}
                </div>
                <div className={styles.thermometerWrapper}>
                  <Thermometer
                    type={config.thermoType}
                    height={40}
                    colors={theme.colors}
                    max={unitsGoal}
                    amount={unitsRaised}
                    units={units}
                  />
                </div>
              </div>
              {config.qr && (
                <div className={styles.qrColumn}>
                  <CampaignQRCode
                    campaign={campaign}
                    theme={theme}
                    styles={styles}
                    isDesktop={true}
                    qrCodeSize={120}
                  />
                  <CardLogos />
                </div>
              )}
            </div>
          </div>
        </div>
      </ThemeBackground>
    </FullScreen>
  );
}
