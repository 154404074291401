import * as React from "react";
import styles from "../styles/components/fullscreenButton.module.css";
import { BaseProps } from "../../common/types";

import FullscreenIcon from "@mui/icons-material/Fullscreen";

interface Props extends BaseProps {
  onClick: () => void;
  color: string;
}

export default function FullscreenButton(props: Props) {
  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    event.preventDefault();
    props.onClick();
  }

  const style = { color: props.color };

  return (
    <button className={styles.buttonFade} onClick={handleClick}>
      <FullscreenIcon style={style} />
    </button>
  );
}
