import "@emotion/react";
import styled from "@emotion/styled/macro";

export const Text = styled.span`
  color: ${(props) => props.theme.colors.text};
  margin: 0;
  position: relative;
  font-weight: ${(props) => props.theme.fontWeights?.regular};
  opacity: 75%;
  ${(props) => props.theme.fontSizes.lg};
`;

export const AccentModifier = styled(Text)`
  font-weight: ${(props) => props.theme.fontWeights?.bold};
  opacity: 100%;
`;

export const HeaderText = styled.span`
  font-family: Poppins;
  line-height: normal;
  font-style: normal;
  text-align: center;
  margin: 0rem 0.5rem;
`;

export const H1Text = styled(HeaderText)`
  font-size: ${(props) => props.theme.fontSizes.lg};
  font-weight: ${(props) => props.theme.fontWeights?.semibold};
`;

export const H2Text = styled(HeaderText)`
  font-size: ${(props) => props.theme.fontSizes.xs};
  font-weight: ${(props) => props.theme.fontWeights?.regular};
`;

export const H2TextLeft = styled(H2Text)`
  text-align: left;
`;
