/* eslint-disable no-lone-blocks */
import "@emotion/react";
import * as S from "../styles/styles";

export default function LoadingScreen() {
  return (
    <>
      <S.Spacer />
      <S.IconIsland>
        <S.Spacer />
        <S.LargeText>{"Loading"}</S.LargeText>
        <S.MdCircularProgress />
        <S.Spacer />
      </S.IconIsland>
      <S.Spacer />
    </>
  );
}
