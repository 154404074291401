import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";
import { CampaignInfo } from "../types";

interface CampaignData {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  url: string;
  setUrl: Dispatch<SetStateAction<string>>;
  campaign?: CampaignInfo;
  setCampaign: Dispatch<SetStateAction<CampaignInfo | undefined>>;
}

interface DipJarProProviderProps {
  children: ReactNode;
}

export const CampaignContext = createContext<CampaignData>({
  name: "",
  setName: () => {},
  url: "",
  setUrl: () => {},
  campaign: undefined,
  setCampaign: () => {},
});

export const CampaignProvider = ({ children }: DipJarProProviderProps) => {
  const [name, setName] = useState<string>("");
  const [url, setUrl] = useState<string>("");
  const [campaign, setCampaign] = useState<CampaignInfo>();

  return (
    <CampaignContext.Provider
      value={{ name, setName, url, setUrl, campaign, setCampaign }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
