import React from "react";

export default class NotFound extends React.Component {
  render() {
    return (
      <div>
        <p style={{ textAlign: "center" }}>404 Page Not Found</p>
      </div>
    );
  }
}
