import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from "react";

interface OrganizationData {
  organizationId?: number;
  setOrganizationId: Dispatch<SetStateAction<number | undefined>>;
  orgJarId?: number;
  setOrgJarId: Dispatch<SetStateAction<number | undefined>>;
  jarId?: number;
  setJarId: Dispatch<SetStateAction<number | undefined>>;
}

interface Props {
  children: ReactNode;
}

export const OrganizationContext = createContext<OrganizationData>({
  organizationId: undefined,
  setOrganizationId: () => {},
  orgJarId: undefined,
  setOrgJarId: () => {},
  jarId: undefined,
  setJarId: () => {},
});

export const OrganizationProvider = ({ children }: Props) => {
  const [organizationId, setOrganizationId] = useState<number | undefined>();
  const [orgJarId, setOrgJarId] = useState<number | undefined>();
  const [jarId, setJarId] = useState<number | undefined>();

  return (
    <OrganizationContext.Provider
      value={{
        organizationId,
        setOrganizationId,
        orgJarId,
        setOrgJarId,
        jarId,
        setJarId,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
