import * as React from "react";
import styles from "../styles/components/themeBackground.module.css";
import { ThemeColorType } from "../../common/theme";
import { BaseProps, CampaignConfig } from "../../common/types";

interface Props extends BaseProps {
  theme: ThemeColorType;
  config: CampaignConfig;
}

export default function TintedBackground(props: Props) {
  const { theme, config } = props;

  const backgroundStyle: React.CSSProperties = {
    backgroundColor: theme.colors.background,
  };
  const tintStyle: React.CSSProperties = {
    color: config.secondaryColor,
  };

  if (config.backgroundImageURL) {
    backgroundStyle.backgroundImage = `url(${config.backgroundImageURL})`;
    tintStyle.background = config.filterColor || "rgba(0, 0, 0, 0.65)";
  } else {
    backgroundStyle.backgroundColor = theme.colors.background;
  }

  if (config.noTint) {
    return (
      <section className={styles.background} style={backgroundStyle}>
        {props.children}
      </section>
    );
  }
  return (
    <section className={styles.background} style={backgroundStyle}>
      <div className={styles.tint} style={tintStyle}>
        {props.children}
      </div>
    </section>
  );
}
