import styles from "../styles/components/cardLogos.module.css";

type Props = {
  className?: string;
};

export default function CardLogos(props: Props) {
  let className = styles.cardLogos;
  if (props.className) {
    className = `${className} ${props.className}`;
  }
  return (
    <img
      className={className}
      src="https://s3.amazonaws.com/dipjar.com/campaignAssets/cc.png"
      alt="Credit card logos"
    />
  );
}
