import * as React from "react";
import styles from "../styles/components/dipjarLogo.module.css";
import { CampaignThemeType } from "../../common/theme";
import { BaseProps } from "../../common/types";

import logoWhite from "../../common/assets/logo-white.png";
import logoColor from "../../common/assets/logo-colored.png";

interface Props extends BaseProps {
  theme: CampaignThemeType;
  className?: string;
}

export default function DipjarLogo(props: Props) {
  let className = styles.logo;
  if (props.className) {
    className = `${className} ${props.className}`;
  }
  return (
    <img
      className={className}
      src={props.theme === "light" ? logoColor : logoWhite}
      alt="DipJar"
    />
  );
}
