import "@emotion/react";
import styled from "@emotion/styled/macro";
import logoWhite from "../../common/assets/logo-white.png";
import { CircularProgress } from "@mui/material";

export const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.assets.background};
`;

export const Rotated270 = styled.div`
  transform: rotate(-90deg);
  display: flex;
  justify-content: center;
  align-items: center;
`;

// NOTE: height and width are determined within DipJarLogo.tsx
export const RoundDJLogo = styled.div`
  box-shadow: 0rem 0.25rem 0.25rem 0rem ${(props) => props.theme.colors.shadow};
  background: ${(props) => props.theme.colors.opacityPrimary};
  border-radius: 100rem;
  position: relative;
  margin: 1rem;
`;

export const WhiteDJLogoImg = styled.div`
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url(${logoWhite});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 75%;
  height: 75%;
  transform: translate(-50%, -50%);
`;

export const CustomerLogoImg = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const MdCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.colors.primary};
  min-height: 3rem;
  min-width: 3rem;
`;

export const LgCircularProgress = styled(CircularProgress)`
  color: ${(props) => props.theme.colors.primary};
  min-height: 5rem;
  min-width: 5rem;
`;
