import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import "@emotion/react";
import { useFormik } from "formik";
import * as yup from "yup";
import { CampaignContext } from "../../common/application/CampaignProvider";
import * as S from "../styles/styles";
import { BsArrowUpSquare, BsArrowDownSquare } from "react-icons/bs";
import logoGrey from "../../common/assets/powered-by-dj-logo-grey.svg";
import { getFullAPI } from "../../common/utils";
import { Header } from "../components/Header";
import { CampaignInfo, ReceiptData } from "../../common/types";
import { generateReceipt } from "../../common/helpers/ReceiptHelpers";

interface Props {
  campaignFetcher?: (campaignId: string) => Promise<CampaignInfo>;
  dipperCreator?: (donorData: DonorData) => Promise<DipperJson>;
  dipperTransactionCreator?: (
    dipperId: DipperJson,
    transactionId: number,
  ) => Promise<DipperTransactionResponse>;
  receiptGetter?: (dipperTransactionId: number) => Promise<ReceiptData>;
  generatePdf?: any;
}

interface DonorData {
  firstName: string;
  lastName: string;
  email: string;
  zipCode: string;
}

interface DipperJson {
  dipper: Dipper;
}

interface Dipper {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  zipCode: string;
}

interface DipperTransaction {
  id: number;
  DipperId: number;
  TransactionId: number;
}

interface DipperTransactionResponse {
  dipperTransaction: DipperTransaction;
}

const fetchCampaign = async (campaignId: string) => {
  const response = await fetch(
    getFullAPI("/public-data/v1/campaigns/get/" + campaignId),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  const fetchedCampaign = await response.json();
  return fetchedCampaign;
};

const createDipper = async (donorData: DonorData) => {
  const response = await fetch(getFullAPI("/api/dipper"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(donorData),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const dipperJson = await response.json();

  return dipperJson;
};
const createDipperTransaction = async (
  dipperJson: DipperJson,
  transactionId: number,
) => {
  const dipper = dipperJson.dipper;

  const dt = {
    DipperId: dipper.id,
    TransactionLogId: transactionId,
  };

  const response = await fetch(getFullAPI("/api/dipperTransaction"), {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(dt),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }

  const dipperTransactionJson = await response.json();
  return dipperTransactionJson;
};

const getReceiptData = async (
  dipperTransactionId: number,
): Promise<ReceiptData> => {
  const response = await fetch(
    getFullAPI(`/api/receipt/${dipperTransactionId}`),
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    },
  );

  const receiptData = await response.json();

  return receiptData;
};

export default function DonorDataScreen({
  generatePdf,
  campaignFetcher = fetchCampaign,
  dipperCreator = createDipper,
  dipperTransactionCreator = createDipperTransaction,
  receiptGetter = getReceiptData,
}: Props) {
  const { campaign, setName, setCampaign, setUrl } =
    useContext(CampaignContext);
  const { campaignId } = useParams<{ campaignId: string }>();
  const { transactionId: unparsedTransactionId } = useParams<{
    transactionId: string;
  }>();

  let parsedTransactionId: number | null = null;
  if (unparsedTransactionId && parseInt(unparsedTransactionId, 10)) {
    parsedTransactionId = parseInt(unparsedTransactionId);
  }

  const postData = async (donorData: DonorData) => {
    try {
      const dipperJson = await dipperCreator(donorData);
      // only create a dipper transaction if there is valid transaction id
      // this allows for dipper creation even if the person has not made a transaction
      if (parsedTransactionId) {
        const dipperTransactionJson = await dipperTransactionCreator(
          dipperJson,
          parsedTransactionId,
        );
        const receiptDataJson = await receiptGetter(
          dipperTransactionJson.dipperTransaction.id,
        );
        setReceiptData(receiptDataJson);
        setSubmitted(true);
      } else {
        setSubmitted(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const fetchedCampaign = await campaignFetcher(campaignId);
      if (fetchedCampaign) {
        setCampaign(fetchedCampaign);
        setName(fetchedCampaign.name);
        setUrl(fetchedCampaign.url);
      }
    }

    fetchData();
  }, [campaignId, campaignFetcher, setCampaign, setName, setUrl]);

  const [submitted, setSubmitted] = useState(false);
  const [receiptData, setReceiptData] = useState<ReceiptData | undefined>(
    undefined,
  );

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      zipCode: "",
    },
    validationSchema: yup.object({
      firstName: yup.string().required("First name is required"),
      lastName: yup.string().required("Last name is required"),
      email: yup
        .string()
        .email("Invalid email format")
        .required("Email is required"),
      zipCode: yup.string().required("Zip code is required"),
    }),
    onSubmit: (values) => {
      postData(values);
    },
  });

  const DonorDataForm = (
    <S.FormWrapper>
      <S.FormHeader>
        {parsedTransactionId
          ? "Please fill out the fields to receive your digital receipt!"
          : "Please fill out the fields to stay in touch!"}
      </S.FormHeader>
      <S.FormRow className="horizontal">
        <S.StyledTextField
          label="First Name"
          inputProps={{ "data-testid": "form-first-name" }}
          {...formik.getFieldProps("firstName")}
          variant="filled"
          error={
            formik.errors.firstName && formik.touched.firstName
              ? true
              : undefined
          }
        />
        <S.StyledTextField
          label="Last Name"
          inputProps={{ "data-testid": "form-last-name" }}
          {...formik.getFieldProps("lastName")}
          variant="filled"
          error={
            formik.errors.lastName && formik.touched.lastName ? true : undefined
          }
        />
      </S.FormRow>
      <S.FormRow>
        <S.StyledTextField
          label="Email Address"
          inputProps={{ "data-testid": "form-email-address" }}
          {...formik.getFieldProps("email")}
          variant="filled"
          error={formik.errors.email && formik.touched.email ? true : undefined}
        />
      </S.FormRow>
      <S.FormRow className="vertical">
        <S.StyledTextField
          label="Zip Code"
          inputProps={{ "data-testid": "form-zip-code" }}
          {...formik.getFieldProps("zipCode")}
          variant="filled"
          error={
            formik.errors.zipCode && formik.touched.zipCode ? true : undefined
          }
        />
      </S.FormRow>
    </S.FormWrapper>
  );

  const SuccessfulSubmitMessage = (
    <S.PostSubmitWrapper>
      <S.SubmissionMessageWrapper>
        <S.SubmissionMessageContent>
          {parsedTransactionId ? (
            <S.StyledDownloadIcon />
          ) : (
            <S.StyledEmailIcon />
          )}
          <S.VerticalColumn>
            <S.H2TextLeft>
              {parsedTransactionId
                ? "Please click below to download your receipt"
                : "Thank you for staying in touch!"}
            </S.H2TextLeft>
          </S.VerticalColumn>
        </S.SubmissionMessageContent>
      </S.SubmissionMessageWrapper>
    </S.PostSubmitWrapper>
  );

  return (
    <S.Wrapper>
      <S.FormOuter onSubmit={formik.handleSubmit}>
        <S.WrapperInnerTop>
          <Header
            primaryText={campaign?.name ?? ""}
            secondaryText={
              parsedTransactionId ? "Thanks For Your Donation" : ""
            }
          />
          {!submitted ? DonorDataForm : SuccessfulSubmitMessage}
        </S.WrapperInnerTop>
        <S.WrapperInnerBottom>
          <S.SubmitAndBranding>
            {!submitted ? (
              <S.SubmitButton variant="contained" type="submit">
                <BsArrowUpSquare />
                {"SUBMIT"}
              </S.SubmitButton>
            ) : (
              parsedTransactionId && (
                <S.SubmitButton
                  type="button"
                  variant="contained"
                  onClick={() => generateReceipt(generatePdf, receiptData)}
                >
                  <BsArrowDownSquare />
                  {"DOWNLOAD"}
                </S.SubmitButton>
              )
            )}
            <S.DJBrandingContainer>
              <S.DjBrandingImg src={logoGrey} alt="Dipjar logo" />
            </S.DJBrandingContainer>
          </S.SubmitAndBranding>
        </S.WrapperInnerBottom>
      </S.FormOuter>
    </S.Wrapper>
  );
}
