import * as React from "react";
import "@emotion/react";
import styled from "@emotion/styled/macro";

export default function SomethingWrong() {
  return (
    <Wrapper>
      <Header>
        <b>Oops.</b> Something went wrong.
      </Header>
      <Subheader>Try refreshing the page, or try again later.</Subheader>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  min-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h2`
  color: #333;
  font-size: ${(props) => props.theme.fontSizes.md}
  line-height: 2rem;
  margin: 0;
  position: relative;
  padding-bottom: 1rem;
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;

const Subheader = styled.span`
  font-size: 1rem;
  line-height: 1;
  margin: 0;
  color: #4a4a4a;
  letter-spacing: 0;
  font-weight: ${(props) => props.theme.fontWeights.regular};
`;
